import { Links } from '@/application/constants/links';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import { ExportRecipes } from './export-recipes/ExportRecipes';
import { MaterialsPage } from './materials-page/MaterialsPage';
import { PromocodesPage } from './promocodes/PromocodesPage';
import { RecipeCalculationsPage } from './recipe-calculations-page/RecipeCalculationsPage';
import { RecipeInvestigationPage } from './recipe-investigation-page/RecipeInvestigationPage';
import { RecipePage } from './recipe-page/RecipePage';
import { RootPage } from './root-page/RootPage';
import { UserProfilePage } from './user-profile-page/UserProfilePage';
import { WelcomePage } from './welcome-page/WelcomePage';

export const authorizedRoutes = () =>
  createBrowserRouter([
    {
      element: <RootPage />,
      children: [
        {
          path: '*',
          element: <Navigate to={Links.Authorized.Dashboard} />,
        },
        {
          path: Links.Authorized.Dashboard,
          element: <WelcomePage />,
        },
        {
          path: Links.Authorized.Folder,
          element: <WelcomePage />,
        },
        {
          path: Links.Authorized.Recipe,
          element: <RecipePage />,
          children: [
            {
              path: Links.Authorized.RecipeCalculations,
              element: <RecipeCalculationsPage />,
            },
            {
              path: Links.Authorized.RecipeInvestigation,
              element: <RecipeInvestigationPage />,
            },
          ],
        },
        {
          path: Links.Authorized.ExportRecipes,
          element: <ExportRecipes />,
        },
        {
          path: Links.Authorized.Materials,
          element: <MaterialsPage />,
        },
        {
          path: Links.Authorized.Promocodes,
          element: <PromocodesPage />,
        },
        {
          path: Links.Authorized.UserProfile,
          element: <UserProfilePage />,
        },
      ],
    },
  ]);
