import { sentryDsn } from '@/application/constants/env-variables';
import { RootStore } from '@/application/redux-store';
import { logoutAction } from '@/application/redux-store/root-reducer';
import { Loading } from '@/components/uikit/suspense/Loading';
import { sendRefreshTokenRequest } from '@/helpers/interceptors/auth/auth-client';
import {
  addLogoutHandler,
  setupAuthInterceptor,
  useIsAuthorized,
} from '@/helpers/interceptors/auth/auth-interceptor';
import { injectLanguageInterceptor } from '@/helpers/interceptors/inject-language-interceptor';
import { sessionAxiosInterceptor } from '@/helpers/interceptors/inject-session-interceptor';
import { backendUri } from '@/helpers/openid/openid-settings';
import { QuerySuspenseErrorWrapper } from '@/helpers/retry-helper';
import { useDonationCards } from '@/hooks/useDonationCards';
import { authorizedRoutes } from '@/pages/authorized/routes';
import { anonymousRoutes } from '@/pages/unauthorized/routes';
import { QueryFactory } from '@/services/api';
import { ValidationProblemDetails } from '@/services/api/api-client';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { useLocalStorage } from 'usehooks-ts';

QueryFactory.setAxiosFactory(() => axios);

setupAuthInterceptor(axios, (authData) => sendRefreshTokenRequest(authData.refresh_token));
addLogoutHandler(() => {
  RootStore.store.dispatch(logoutAction());
  window.history.pushState(null, '', backendUri);
});
axios.interceptors.request.use(injectLanguageInterceptor);
axios.interceptors.request.use(sessionAxiosInterceptor);

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: sentryDsn(),
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

export const App = () => {
  const isAuth = useIsAuthorized();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    setUserIsNotAllowedToCopyRecipe,
    setMaximumPhotosUploadCountReached,
    setMaxRecipeCalculatorsPerRecipeCountReached,
    setUserIsNotAllowedToCopyRecipeBecauseOfCalcs,
    setAddingNewCalculationsIntoInvestigationForbidden,
  } = useDonationCards();

  const theme = useMemo(() => {
    return createTheme({
      typography: {
        subtitle2: {
          fontWeight: 300,
          fontSize: '0.75rem',
          lineHeight: '1.25rem',
        },
        body1: {
          fontWeight: 400,
          fontSize: '1rem',
          lineHeight: '1.5rem',
        },
        body2: {
          fontWeight: 400,
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
        },
        h1: {
          fontWeight: 600,
          fontSize: '2rem',
          lineHeight: '2rem',
          color: '#2f80ed',
        },
        h2: {
          fontWeight: 600,
          fontSize: '0.95rem',
        },
        fontFamily: 'IBM Plex Sans',
      },
      palette: {
        primary: {
          main: '#2f80ed',
        },
        error: {
          main: '#d32f2f',
        },
      },
    });
  }, []);

  const queryClient = useMemo(() => {
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          useErrorBoundary: true,
          suspense: false,
        },
        mutations: {
          onError: (error) => {
            if ((error as ValidationProblemDetails)?.detail) {
              const errorMessage: string = (error as ValidationProblemDetails).detail ?? '';
              if (errorMessage === 'ValidationErrors.UserIsNotAllowedToCopyRecipe') {
                setUserIsNotAllowedToCopyRecipe(true);
                return;
              }
              if (errorMessage === 'ValidationErrors.MaximumPhotosUploadCountReached') {
                setMaximumPhotosUploadCountReached(true);
                return;
              }
              if (errorMessage === 'ValidationErrors.MaxRecipeCalculatorsPerRecipeCountReached') {
                setMaxRecipeCalculatorsPerRecipeCountReached(true);
                return;
              }
              if (errorMessage === 'ValidationErrors.UserIsNotAllowedToCopyRecipeBecauseOfCalcs') {
                setUserIsNotAllowedToCopyRecipeBecauseOfCalcs(true);
                return;
              }
              if (
                errorMessage === 'ValidationErrors.AddingNewCalculationsIntoInvestigationForbidden'
              ) {
                setAddingNewCalculationsIntoInvestigationForbidden(true);
                return;
              }
              enqueueSnackbar(t(errorMessage), { variant: 'error' });
            }
            enqueueSnackbar(t('InternalErrors.UnexpectedError'), { variant: 'error' });
          },
        },
      },
    });
    addLogoutHandler(() => {
      queryClient.clear();
    });
    return queryClient;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fallback = useMemo(() => {
    return <Loading loading={true} />;
  }, []);

  return (
    <Suspense fallback={fallback}>
      <QueryClientProvider client={queryClient}>
        <Provider store={RootStore.store}>
          <PersistGate loading={fallback} persistor={RootStore.persistor}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <QuerySuspenseErrorWrapper>
                  <RouterProvider router={isAuth ? authorizedRoutes() : anonymousRoutes()} />
                </QuerySuspenseErrorWrapper>
              </ThemeProvider>
            </StyledEngineProvider>
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </Suspense>
  );
};
