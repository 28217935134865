import { SortRecipeCalculationsBy } from '@/pages/authorized/recipe-page/common/recipe-header/sort-recipe-calculations-selector/consts';
import { RecipeCalculationDto } from '@/services/api/api-client';
import { compareAsc } from 'date-fns';

export const sortRecipeCalculations = (
  a: RecipeCalculationDto,
  b: RecipeCalculationDto,
  sortBy: SortRecipeCalculationsBy,
) => {
  switch (sortBy) {
    case SortRecipeCalculationsBy.Name:
      return sortByName(a, b);
    case SortRecipeCalculationsBy.CreatedAt:
      return sortByCreatedAt(a, b);
  }
};

const sortByCreatedAt = (a: RecipeCalculationDto, b: RecipeCalculationDto) => {
  return compareAsc(a.createdAt, b.createdAt);
};

const sortByName = (a: RecipeCalculationDto, b: RecipeCalculationDto) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};
