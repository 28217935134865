import { getRecipeRoute } from '@/application/constants/links';
import { Tab } from '@/pages/authorized/recipe-page/common/recipe-header/tab-selector/TabSelector';
import { usePublicFolderParam } from '@/pages/authorized/root-page/common/recipe-folders/hooks';
import constate from 'constate';
import { useMatch } from 'react-router';

import { useRecipeParam } from './useRecipeParam';

export const [SelectedTabProvider, useSelectedTab] = constate(() => {
  const { recipeId } = useRecipeParam();
  const { publicFolderId } = usePublicFolderParam();
  const match = useMatch(getRecipeRoute({ folderId: publicFolderId, recipeId }));

  return {
    selectedTab: Boolean(match) ? Tab.Calculators : Tab.Investigation,
  };
});
