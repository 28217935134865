import { StullChartPoint } from '@/components/stull-chart/StullChart';
import { SortRecipeCalculationsBy } from '@/pages/authorized/recipe-page/common/recipe-header/sort-recipe-calculations-selector/consts';
import { sortRecipeCalculations } from '@/pages/authorized/recipe-page/common/recipe-header/sort-recipe-calculations-selector/utils';
import { FormulaViewType, RecipeCalculationQuery } from '@/services/api/api-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import constate from 'constate';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { addNewRecipeCalculation } from '../../helpers/queries/query-helpers';

import { useRecipeParam } from './useRecipeParam';

type SelectedCalculatorState = {
  id: number;
  isReadonly: boolean;
};

type DirtyCalculationsState = {
  [calculationId: number]: boolean;
};

export const [RecipeCalculationsProvider, useRecipeCalculations] = constate(() => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { recipeId } = useRecipeParam();

  const [currentSorting, setCurrentSorting] = useState<SortRecipeCalculationsBy>(
    SortRecipeCalculationsBy.CreatedAt,
  );

  const [selectedCalculation, setSelectedCalculator] = useState<SelectedCalculatorState | null>(
    null,
  );

  const [stullPoints, setStullPoints] = useState<StullChartPoint[]>([]);

  const [dirtyCalculations, setDirtyCalculations] = useState<DirtyCalculationsState>({});

  const anyDirtyCalculation = useMemo(
    () => Object.values(dirtyCalculations).some((x) => x),
    [dirtyCalculations],
  );

  const pushStullPoint = useCallback(
    (point: StullChartPoint) =>
      setStullPoints((state) => [
        ...state.filter((x) => x.recipeCalculationId !== point.recipeCalculationId),
        point,
      ]),
    [stullPoints],
  );

  const removeStullPoint = useCallback(
    (calculationId: number) =>
      setStullPoints((state) => state.filter((x) => x.recipeCalculationId !== calculationId)),
    [stullPoints],
  );

  const calculationsQuery = RecipeCalculationQuery.useGetAllQuery(recipeId);

  const calculations = useMemo(
    () =>
      calculationsQuery.data
        // Filtering deleted calculation on frontend part
        // to be able to show removed calculators in investigation editor
        ?.filter((x) => !x.isDeleted)
        .sort((a, b) => sortRecipeCalculations(a, b, currentSorting)) ?? [],
    [calculationsQuery.data, currentSorting],
  );

  const removedCalculations = useMemo(
    () =>
      calculationsQuery.data
        ?.filter((x) => x.isDeleted)
        .sort((a, b) => sortRecipeCalculations(a, b, currentSorting)) ?? [],
    [calculationsQuery.data, currentSorting],
  );

  const addCalculationMutation = useMutation(
    () =>
      RecipeCalculationQuery.Client.create(recipeId, {
        recipe: [],
        name: t('Page.Recipe.DefaultCalculationName'),
        formulaViewType: FormulaViewType.SegerFormula,
        includeAdditionsIntoCalculations: false,
        isLocked: false,
      }),
    {
      onSuccess: (createdRecipeCalculation) => {
        enqueueSnackbar(t('Page.Recipe.Notification.CalculationAdded'), { variant: 'info' });
        addNewRecipeCalculation(queryClient, recipeId, createdRecipeCalculation);
      },
    },
  );

  // Resets states to default and cleans up Stull chart points.
  useEffect(() => {
    setSelectedCalculator(null);
    // In cases when recipe tab was switched to another recipe and back to opened previous recipe
    // this effect runs after previous points have been pushed to the state,
    // so we will filter out all points from another recipes instead of set of state as empty Array,
    // otherwise it leads to empty state and no any actual point will be shown on chart.
    setStullPoints((state) => state.filter((point) => point.recipeId === recipeId));
  }, [recipeId]);

  return {
    stullPoints,
    calculations,
    removedCalculations,
    currentSorting,
    dirtyCalculations,
    anyDirtyCalculation,
    selectedCalculation,
    pushStullPoint,
    removeStullPoint,
    changeSorting: setCurrentSorting,
    changeCalculationDirty: setDirtyCalculations,
    changeSelectedCalculator: setSelectedCalculator,
    addCalculation: addCalculationMutation.mutateAsync,
    areCalculationsLoading: calculationsQuery.isLoading,
    isAddCalculationLoading: addCalculationMutation.isLoading,
  };
});
