import { LanguageProvider } from '@/application/localization/LanguageProvider';
import FrownIcon from '@/assets/icons/frown.svg?react';
import SmileIcon from '@/assets/icons/smile.svg?react';
import { CloseSnackbarButton } from '@/components/snackbar/CloseSnackbarButton';
import { SnackbarContent } from '@/components/snackbar/SnackbarContent';
import { Loading } from '@/components/uikit/suspense/Loading';
import { postServerLogOut, setAuthData } from '@/helpers/interceptors/auth/auth-interceptor';
import { backendUri } from '@/helpers/openid/openid-settings';
import { OpenIdCallback } from '@/helpers/openid/OpenIdCallback';
import { DonationCardsProvider } from '@/hooks/useDonationCards';
import { Grow } from '@mui/material';
import { formatISO } from 'date-fns';
import 'mac-scrollbar/dist/mac-scrollbar.css';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';

// to send dates to backend in local timezone (not in UTC)
Date.prototype.toISOString = function () {
  return formatISO(this);
};

const el = document.getElementById('root');
if (!el) {
  throw new Error('Root container is missing in index.html.');
}

const AppWithOpenIdCallback = () => {
  return (
    <OpenIdCallback
      signInRedirectHandler={(user) => {
        setAuthData({
          access_token: user.access_token,
          refresh_token: user.refresh_token!,
        });
        window.history.pushState(null, '', backendUri);
      }}
      signOutRedirectHandler={() => {
        postServerLogOut();
      }}
      loading={<Loading loading={true} />}
    >
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        TransitionComponent={Grow}
        iconVariant={{
          success: <SmileIcon style={{ marginRight: '15px' }} />,
          error: <FrownIcon style={{ marginRight: '15px' }} />,
          warning: <FrownIcon style={{ marginRight: '15px' }} />,
          info: <SmileIcon style={{ marginRight: '15px' }} />,
        }}
        Components={{
          success: SnackbarContent,
          error: SnackbarContent,
          info: SnackbarContent,
          warning: SnackbarContent,
        }}
        action={(snackbarKey) => <CloseSnackbarButton snackbarKey={snackbarKey} />}
        autoHideDuration={3000}
      >
        <LanguageProvider>
          <DonationCardsProvider>
            <App />
          </DonationCardsProvider>
        </LanguageProvider>
      </SnackbarProvider>
    </OpenIdCallback>
  );
};

const root = ReactDOM.createRoot(el);

root.render(
  import.meta.env.PROD ? (
    <React.StrictMode>
      <AppWithOpenIdCallback />
    </React.StrictMode>
  ) : (
    <AppWithOpenIdCallback />
  ),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
